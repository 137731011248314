body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  fill: #673ab7;
}

.app-bar-logo {
  height: 40px;
  width: auto;
}

.mobile-menu-link {
  color: white;
}

.mobile-menu-link:visited {
  color: white;
}

/* Managed Users */
.managed-user-card-content {
  padding: 24px !important;
}

.managed-user-card:hover {
  background-color: #1de9b6;
}

.managed-user-card:hover p {
  color: black;
}

.managed-user-card-text {
  font-size: 22px !important;
  margin: 0px !important;
}

/* No Page */
.routerlink, .routerlink:visited {
  color: #00bfa5;
}

.gif404 {
  max-width: 100%;
  height: auto;
}

/* Wells */
.well-page-title {
  margin: 40px 0px 0px 0px;
}

.well-page-subtitle {
  margin: 0px 0px 20px 0px;
  font-style: italic;
}

.well-card-content {
  padding: 24px !important;
}

.well-card:hover {
  background-color: #1de9b6;
}

.well-card:hover p {
  color: black;
}

.well-card-text {
  font-size: 22px !important;
  margin: 0px !important;
}

.dialog-box-bar {
  text-align: right;
  background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

/* WellMembers */
.well-member-list {
  padding-left: 0px !important;
}

/* Item Edit */
.store-card {
  background-color: #282727 !important;
}